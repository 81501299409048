/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ConfigRevision, NodeConfig } from '@/client';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Title } from '@/primitives/Title';
import { tokens } from '@/theme';
import { Text } from '@/primitives/Text';

export type NodeConfigurationRevisionSourceCodeProps = {
  nodeConfiguration: NodeConfig;
  nodeConfigRevision: ConfigRevision;
  getNodeConfigurationPath: () => string;
};

export function NodeConfigurationRevisionSourceCode({
  nodeConfiguration,
  nodeConfigRevision,
  getNodeConfigurationPath,
}: NodeConfigurationRevisionSourceCodeProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Flex gap="xxs">
        <BuildOutlined />
        <Link
          to={getNodeConfigurationPath()}
          style={{
            fontSize: '1.1875rem',
            fontFamily: 'DM Mono',
            color: tokens.color.text.textSubtle,
          }}
          dataTestId="node-config-name"
        >
          {nodeConfiguration.name}
        </Link>
      </Flex>
      <Title
        style={{ fontWeight: 600, padding: '12px 0 24px 0' }}
        level={4}
      >{`Revision #${nodeConfigRevision.revisionNumber} · ${nodeConfigRevision.description}`}</Title>
      <Text type="subtle" style={{ fontWeight: 300, marginBottom: '12px' }} data-testid="revision-id" mono copyable>
        {nodeConfigRevision.id}
      </Text>
      <div style={{ flex: 1, overflowY: 'auto', fontFamily: 'DM Mono' }}>
        <SyntaxHighlighter
          language="go"
          style={docco}
          showLineNumbers
          lineNumberStyle={{
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '15px',
            height: '26px',
            color: '#191919',
            fontSize: '1rem',
            backgroundColor: 'rgb(25, 25, 25, 0.04)',
            fontFamily: 'DM Mono',
            width: '50px',
          }}
          customStyle={{
            backgroundColor: 'white',
            border: '1px solid rgb(25, 25, 25, 0.1)',
            fontSize: '14px',
            padding: '0',
          }}
        >
          {ensureThirtyLines(nodeConfigRevision.source)}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

function ensureThirtyLines(source = '') {
  const lines = source.split('\n');
  const lineCount = lines.length;

  if (lineCount < 15) {
    const additionalLines = 15 - lineCount;
    for (let i = 0; i < additionalLines; i += 1) {
      lines.push('');
    }
  }

  return lines.join('\n');
}
