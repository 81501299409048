/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { PaginatedTable } from '../PaginatedTable';
import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { UpdateParams } from '@/utils/params';
import { NodesColumns, getNodesColumns } from './getNodesColumns';
import { ExpandedRow } from './ExpandedRow';

export type NodesTableProps = {
  tenantID: string;
  nodesQuery: UseQueryResult<PaginatedNodesResponse>;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  columns: NodesColumns[];
  tags?: Tag[];
  getNodeConfigurationPath: (nodeConfigId: string) => string;
  onAssignConfiguration?: (node: Node) => void;
};

const expandedRowRender = (record: Node) => <ExpandedRow node={record} />;

export function NodesTable({
  nodesQuery,
  onParamsChange,
  columns,
  tags,
  tenantID,
  getNodeConfigurationPath,
  onAssignConfiguration,
}: NodesTableProps) {
  const { t } = useMimicTranslation('nodes');
  const filters = nodesQuery.data?.meta.filters;
  const columnsShown = getNodesColumns(
    columns,
    tenantID,
    t,
    getNodeConfigurationPath,
    tags,
    filters,
    onAssignConfiguration,
  );
  return (
    <PaginatedTable
      rowKey="id"
      data-testid="node-table"
      columns={columnsShown}
      paginatedResource={nodesQuery}
      onParamsChange={onParamsChange}
      expandable={{
        expandedRowRender,
        rowExpandable: () => true,
      }}
    />
  );
}
