/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Modal } from 'antd';

import { useState } from 'react';
import { OsIcon } from './Icons/OsIcon';
import { tokens } from '@/theme';
import { Container } from '@/primitives/Container';
import { Button } from '@/primitives/Button';
import { Space } from '@/primitives/Space';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { Dropdown } from '@/primitives/Dropdown';

const iconColor = tokens.color.components.icon.iconDefault;

const items: MenuProps['items'] = [
  {
    label: 'Windows',
    key: 'windows',
    icon: <OsIcon name="windows" color={iconColor} />,
  },
  {
    label: 'Redhat Linux',
    key: 'redhat',
    icon: <OsIcon name="redhat" color={iconColor} />,
  },
  {
    label: 'Debian Linux',
    key: 'debian',
    icon: <OsIcon name="debian" color={iconColor} />,
  },
];

interface ModalDefinition {
  title: string;
  content: JSX.Element;
}

interface Modals {
  windows: ModalDefinition;
  redhat: ModalDefinition;
  debian: ModalDefinition;
}

const listStyle = {
  marginBottom: tokens.size.listSpacing.sm,
};

export interface InstallerDropdownProps {
  action: (key: string) => void;
}

export function InstallerDropdown({ action }: InstallerDropdownProps) {
  const { t } = useMimicTranslation('installer');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKind, setModalKind] = useState('windows');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    setModalKind(key as keyof Modals);
    showModal();
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Space>
      <Dropdown menu={menuProps}>
        <Button type="primary">
          <Space>
            {t('download_installer')}
            <span style={{ fontSize: 'smaller' }}>
              <DownOutlined />
            </span>
          </Space>
        </Button>
      </Dropdown>
      <Modal
        title={t('download_installer')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="done" type="primary" onClick={handleOk} dataTestId="download_installer_modal_done">
            {t('done_button')}
          </Button>,
        ]}
        width={tokens.size.modal.md}
      >
        <Container>
          <ol data-testid="download_installer_modal">
            <li style={listStyle}>
              {t('steps.default.step1_pre_action')}
              <Button
                type="inline-link"
                onClick={() => {
                  action(modalKind);
                }}
                dataTestId="download_installer_modal_download"
              >
                {t('installer')}
              </Button>
              {t('steps.default.step1_post_action')}
            </li>
            <li style={listStyle}>{t('steps.default.step2')}</li>
            <li style={listStyle}>{t('steps.default.step3')}</li>
          </ol>
        </Container>
      </Modal>
    </Space>
  );
}
