/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Pagination } from '@/client';
import { paginationRange } from '@/utils/pagination';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export type PaginationInfoParams = {
  pagination?: Pagination;
};

export function PaginationInfo({ pagination }: PaginationInfoParams) {
  const { t } = useMimicTranslation('pagination');

  if (!pagination) {
    return null;
  }

  const { start, end, total } = paginationRange(pagination);
  return <div style={{ fontSize: '0.875rem' }}>{t('summary', { start, end, total })}</div>;
}
