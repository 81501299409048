/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { MgmtPlaneClient } from '.';
import type { ApiRequestOptions } from './core/ApiRequestOptions';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { CancelablePromise } from './core/CancelablePromise';
import type { OpenAPIConfig } from './core/OpenAPI';
import { request as __request } from './core/request';

type IgnoredEndpoints = { [key: string]: { [key: number]: string[] } };

export class ApiRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiRequestError';
  }
}

// Define the endpoints (method + http status) that should be ignored by Sentry.
const sentryIgnoredEndpoints: IgnoredEndpoints = {
  get: {
    401: [`${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/users/profile`],
  },
};

// Helped method to get the ignored endpoints for a given method and status.
const getIgnoredEndpoints = (method: string, status: number) => {
  const methodStatuses = sentryIgnoredEndpoints[method];
  if (!methodStatuses) {
    return [];
  }
  const endpoints = methodStatuses[status];
  return endpoints || [];
};

// Create an axios client with a response interceptor that captures exceptions with Sentry.
const axiosClient = axios.create();
axiosClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { url } = error.config!;
    const isRequestSentryIgnored = getIgnoredEndpoints(error.config!.method!, error.response!.status).includes(url!);

    if (error.response && error.response.status >= 400 && !isRequestSentryIgnored) {
      const errorDescription = `${error.config?.method} request to ${url} failed with status ${error.response.status}`;
      Sentry.captureException(new ApiRequestError(errorDescription));
    }
    return Promise.reject(error);
  },
);

// AxiosHttpRequest is a class that extends BaseHttpRequest to provide a request method that uses our custom axios client.
class AxiosHttpRequest extends BaseHttpRequest {
  constructor(config: OpenAPIConfig) {
    super(config);
  }

  public override request<T>(options: ApiRequestOptions<T>): CancelablePromise<T> {
    return __request(this.config, options, axiosClient);
  }
}

export const mgmtPlaneAPIClient = new MgmtPlaneClient(
  {
    BASE: window.mimicConfig.MIMIC_MGMT_PLANE_API as string,
    WITH_CREDENTIALS: true,
  },
  AxiosHttpRequest,
);

export type PaginationParams = {
  number?: number;
  size?: number;
};
